.checkBtn {
  width: 100%;
  padding: 6px 16px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #364f6a;
  border-radius: 5px;
  color: #90adc6;
  background-color: #364f6a;
}

.checkBtn:hover {
  color: #f8bc02;
  background-color: #364f6a;
  border: 1px solid #364f6a;
}

.checkBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .checkBoxContainer {
    margin-top: 0px;
  }
}

.circleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.circle {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #7e7e7e;
}

.correctly {
  background-color: green;
}

.incorrectly {
  background-color: red;
}
