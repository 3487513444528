.container {
  width: 100%;
  height: 100%;
  padding: 8px 10px 14px 10px;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: #e0e3e8;
}

@media screen and (min-width: 1280px) {
  .container {
    width: 700px;
    height: 200px;
  }
}

.title {
  font-size: 18px;
  margin-bottom: 8px;
  color: #364f6a;
}

@media screen and (min-width: 1280px) {
  .title {
    font-size: 20px;
    margin-bottom: 12px;
  }
}

.boxContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .boxContainer {
    gap: 8px;
  }
}

@media screen and (min-width: 1280px) {
  .boxContainer {
    gap: 6px;
    margin-bottom: 0px;
  }
}

.box {
  /* user-select: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 2px solid black;
}

@media screen and (min-width: 1280px) {
  .box {
    width: 30px;
    height: 30px;
  }
}

.disabledBox {
  background-color: grey;
}
