@media screen and (min-width: 320px) {
  .userNavWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px 0px 0px;
  }
}

@media screen and (min-width: 768px) {
  .userNavWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 0px 0px;
  }
}

.userNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

@media screen and (min-width: 768px) {
  .userNav {
    justify-content: space-between;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.64px;
    padding: 8px 8px;
  }
}

.link {
  color: #111;
  text-decoration: none;
}

.active {
  color: #f8bc02;
  text-decoration: none;
}

.userName {
  min-width: 41px;
  max-width: 120px;

  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 500;
  font-size: 22px;
  /* line-height: 1.75;
  letter-spacing: 0.02857em; */
  text-transform: uppercase;
  color: #364f6a;
}

@media screen and (min-width: 768px) {
  .userName {
    color: white;
  }
}

@media screen and (min-width: 1280px) {
  .userName {
    font-size: 14px;
  }
}
