.headerNav {
  display: flex;
  color: white;
  justify-content: space-between;
  padding: 9px 12px;
}

@media screen and (min-width: 768px) {
  .headerNav {
    gap: 22px;
    padding: 0px 10px;
  }
}

@media screen and (min-width: 1280px) {
  .headerNav {
    gap: 0;
    justify-content: space-between;
  }
}
