.ruleWrap {
  width: 100%;
  height: fit-content;
  border: 1px solid var(--green);
  border-radius: 4px;
  margin-top: 26px;
  margin-bottom: 26px;
  background-color: white;
  padding: 8px;
  align-items: center;
  display: flex;
  /* color: white; */
}

.rule {
  align-items: center;
  display: flex;
  gap: 10px;
}
