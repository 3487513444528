.input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarUpload {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  object-fit: cover;
  object-position: center;
  border: none;
  border-radius: 50%;
}
@media screen and (min-width: 1280px) {
  .avatarUpload {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.img {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  border: none;
}

@media screen and (min-width: 768px) {
  .img {
    width: 130px;
    height: 130px;
  }
}

@media screen and (min-width: 1280px) {
  .img {
    width: 220px;
    height: 220px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

/* ------- Base Btn ------- */

.baseBtn {
  display: inline-block;
  background-color: transparent;
  border: none;
  opacity: 0.75;
  cursor: pointer;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* ------- Photo Btns ------- */

.photoBtns {
  /* position: absolute;
  top: 190px;
  left: 50%;
  transform: translate(-50%, 0); */

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-width: 104px;

  font-size: 12px;
  font-weight: 400;
  line-height: 1.83;
  letter-spacing: 0.48px;
  color: var(--unnamed, #111);

  @media screen and (min-width: 768px) {
    margin-top: 8px;
  }

  /* @media screen and (min-width: 1280px) {
    top: 215px;
    left: 50%;
    transform: translate(-50%, 0);
  } */
}

/* ------- Confirm Btn ------- */

.confirmBtn {
  /* display: inline-block; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  opacity: 0.75;
  cursor: pointer;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.confirmBtn:hover,
.confirmBtn:focus {
  opacity: 1;
  box-shadow: rgba(100, 100, 111, 0.2);
}

.confirm {
  font-size: 17px;
}

/* ------- Edit Photo Btn ------- */

.cameraBtn {
  display: inline-block;
  background-color: transparent;
  border: none;
  opacity: 0.75;
  cursor: pointer;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

  display: inline-flex;
  align-items: center;
  gap: 8px;

  font-size: 17px;
  /* color: #399e5a; */
  margin-top: 4px;
}
.cameraBtn:hover,
.cameraBtn:focus {
  opacity: 1;
  box-shadow: rgba(100, 100, 111, 0.2);
}

@media screen and (min-width: 768px) {
  .cameraBtn {
    margin-top: 9px;
  }
}

/* @media screen and (min-width: 1280px) {

  }  */
