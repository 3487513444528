.imgSchema {
  width: 288px;
}

@media screen and (min-width: 390px) {
  .imgSchema {
    width: 358px;
  }
}

@media screen and (min-width: 390px) {
  .imgSchema {
    width: 358px;
  }
}

@media screen and (min-width: 768px) {
  .imgSchema {
    width: 556px;
  }
}

@media screen and (min-width: 1280px) {
  .imgSchema {
    width: 720px;
  }
}

.list {
  padding-left: 0px;
}

.listItem {
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .listItem {
    margin-left: 12px;
  }
}

.listLast {
  padding-left: 0px;
  /* margin-bottom: 74px; */
}
