.addWordsToContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100%;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  width: 288px;
  /* background-color: #e0e3e8; */
  margin: 0 auto;
  padding: 10px;
}

@media screen and (min-width: 390px) {
  .addWordsToContainer {
    width: 358px;
  }
}

@media screen and (min-width: 768px) {
  .addWordsToContainer {
    width: 556px;
    gap: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .addWordsToContainer {
    width: 800px;
  }
}

.availableWords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 4px;
  width: 100%;
  min-height: 42px;
  height: 100%;
  background-color: var(--yellow);
}

@media screen and (min-width: 390px) {
  .availableWords {
    width: 334px;
  }
}

@media screen and (min-width: 768px) {
  .availableWords {
    width: 530px;
    gap: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .availableWords {
    width: 660px;
    min-height: 44px;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 288px;
  gap: 20px;
  padding: 2px 20px;
}

@media screen and (min-width: 768px) {
  .container {
    flex-direction: column;
    margin: 0 auto;
    width: 350px;
    gap: 10px;

    padding: 2px 8px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    width: 640px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.wordContainerWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .wordContainerWrap {
    flex-direction: row;
    width: 530px;
  }
}

.wordContainers {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .wordContainers {
    display: flex;
    flex-direction: row;

    gap: 10px;
    align-items: center;
  }
}

.textWrap {
  display: flex;
  padding: 2px 8px 2px 8px;
  /* justify-content: center; */
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  min-height: 30px;
  min-width: 90px;
  width: fit-content;
  height: fit-content;
  flex-wrap: wrap;
  /* row-gap: 10px;
  column-gap: 10px; */
  background-color: white;
  gap: 2px;
}

/* @media screen and (min-width: 390px) {
  .textWrap {
    width: 160px;
  }
}
@media screen and (min-width: 768px) {
  .textWrap {
    width: 320px;
  }
} */

.text {
  color: #364f6a;
  justify-content: center;
  align-items: center;
  word-break: keep-all;
  font-weight: 400;
}

.textPersons {
  color: var(--yellow);
  justify-content: center;
  align-items: center;
  word-break: keep-all;
  font-weight: 600;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .textPersons {
    margin-bottom: 0px;
    font-size: 16px;
  }
}

.cardWrap {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 8px;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background-color: white;
  border: 1px solid #364f6a;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  height: 26px;
  color: #364f6a;
  font-weight: 400;
}

@media screen and (min-width: 1280px) {
  .card {
    font-weight: 500;
  }
}

.card:hover {
  background-color: #e0e3e8;
}

.cardInContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 2px 8px;
  background-color: white;
  /* border: 1px solid #364f6a;
  border-radius: 4px; */
  /* cursor: pointer;
  user-select: none; */
  height: 24px;
  color: #364f6a;
  font-weight: 400;
}

@media screen and (min-width: 1280px) {
  .cardInContainer {
    font-weight: 500;
  }
}

.cardInContainer:hover {
  cursor: pointer;
  background-color: #e0e3e8;
}

.wordContainer {
  flex: 1;
  padding: 2px 0px 2px 0px;
  border: 1px solid var(--main-color);

  /* border: 1px solid rgba(0, 0, 0, 0.23); */
  border-radius: 4px;
  min-height: 30px;
  min-width: 160px;
  width: fit-content;
  flex-wrap: wrap;
  background-color: white;
}

.wordContainer:hover {
  cursor: pointer;
  background-color: #e0e3e8;
}

/* @media screen and (min-width: 390px) {
  .wordContainer {
    width: 160px;
  }
}

@media screen and (min-width: 768px) {
  .wordContainer {
    width: 320px;
  }
} */

.persons {
  min-width: 94px;
  width: fit-content;
}

/* @media screen and (min-width: 1280px) {
  .wordContainer {
    width: 320px;
  }
} */

/* .rightContainer {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #e0e3e8;
  border-radius: 4px;
  height: 60px;
  width: 266px;
  flex-wrap: wrap;
  background-color: white;
}

@media screen and (min-width: 390px) {
  .rightContainer {
    width: 320px;
    height: 60px;
  }
}

@media screen and (min-width: 768px) {
  .rightContainer {
    width: 260px;
  }
}

@media screen and (min-width: 1280px) {
  .rightContainer {
    height: 60px;
    width: 320px;
  }
} */

.incorrect {
  border: 1px solid red;
}

.correct {
  border: 1px solid green;
}

.selected {
  border: 1px solid #399e5a;
}
