.lessonWrap {
  margin-bottom: 0px;
}

.lessonTitle {
  color: var(--yellow);
  margin-top: 32px;
  margin-bottom: 17px;
  font-size: 26px;
}

.lessonFirstTask {
  color: var(--main-color);
  font-size: 17px;
  margin-bottom: 22px;
  margin-top: 14px;
}

@media screen and (min-width: 768px) {
  .lessonFirstTask {
    margin-bottom: 26px;
    margin-top: 14px;
  }
}

@media screen and (min-width: 768px) {
  .lessonFirstTask {
    font-size: 18px;
  }
}

.lessonTask {
  color: var(--main-color);
  font-size: 17px;
  margin-bottom: 16px;
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  .lessonTask {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .lessonTask {
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 18px;
  }
}

.lessonVideo {
  width: 288px;
  height: 170px;
}

@media screen and (min-width: 390px) {
  .lessonVideo {
    width: 358px;
    height: 200px;
  }
}

@media screen and (min-width: 768px) {
  .lessonVideo {
    width: 556px;
    height: 310px;
  }
}

@media screen and (min-width: 1280px) {
  .lessonVideo {
    width: 818px;
    height: 456px;
  }
}

.vimeo {
  padding: 56.25% 0 0 0;
  position: relative;
}

.vimeoVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.summary {
  width: 288px;
  height: 170px;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (min-width: 390px) {
  .summary {
    width: 358px;
    height: 212px;
  }
}

@media screen and (min-width: 768px) {
  .summary {
    width: 556px;
    height: 324px;
  }
}

@media screen and (min-width: 1280px) {
  .summary {
    width: 720px;
    height: 420px;
  }
}

.text {
  font-size: 16px;
}

@media screen and (min-width: 1280px) {
  .text {
    font-size: 18px;
  }
}

/* Vocabulary in lessons */

.spanishWord {
  font-weight: 600;
  font-size: 16px;
  color: var(--main-color);
}

@media screen and (min-width: 1280px) {
  .spanishWord {
    font-size: 18px;
  }
}

.spanishWordTranslation {
  font-weight: 400;
  font-size: 16px;
  color: black;
}

@media screen and (min-width: 1280px) {
  .spanishWordTranslation {
    font-size: 18px;
  }
}

/* Title ot the text */

.textTitleWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.textTitle {
  color: var(--green);
  font-size: 17px;
  font-weight: 500;
}

@media screen and (min-width: 1280px) {
  .textTitle {
    font-size: 18px;
  }
}

/* .textListOfWords {
  font-size: 17px;
  font-weight: 500;
} */

/* Available words to choose in exercises */

.availableWordsWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  /* row-gap: 10px;
  column-gap: 10px; */
}

.availableWords {
  display: flex;
  justify-content: center;

  align-items: center;
  padding: 2px 4px;
  background-color: white;
  border: 1px solid #364f6a;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  height: 30px;
  color: #364f6a;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .availableWords {
    height: 34px;
    padding: 2px 8px;
    font-weight: 500;
  }
}
@media screen and (min-width: 1280px) {
  .availableWords {
    height: 34px;
    padding: 2px 8px;
    font-weight: 500;
  }
}

.availableWords:hover,
.availableWords:focus {
  background-color: #e0e3e8;
}
