.container {
  width: 100%;
  height: 100%;
  padding: 8px 6px;
  /* border: 1px solid #e0e3e8; */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: var(--timberwolf);
  gap: 10px;
}

@media screen and (min-width: 390px) {
  .container {
    padding: 8px 8px;
  }
}
@media screen and (min-width: 1280px) {
  .container {
    flex-direction: row;
    padding: 8px 10px;
    gap: 18px;
    width: 600px;
    height: 60px;
  }
}

.boxContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

@media screen and (min-width: 768px) {
  .boxContainer {
    gap: 6px;
    flex-wrap: nowrap;

    /* margin-bottom: 10px; */
  }
}

@media screen and (min-width: 1280px) {
  .boxContainer {
    margin-bottom: 0px;
  }
}

.box {
  /* user-select: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  width: 100%;
  height: 20px;
  border: 2px solid black;
}

/* @media screen and (min-width: 1280px) {
  .box {
    width: 30px;
    height: 30px;
  }
} */

.disabledBox {
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 2px solid black;
}
