.addWordsToContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100%;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  width: 288px;
  margin: 0 auto;
  padding: 10px;
}

@media screen and (min-width: 390px) {
  .addWordsToContainer {
    width: 358px;
  }
}

@media screen and (min-width: 768px) {
  .addWordsToContainer {
    width: 556px;
    gap: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .addWordsToContainer {
    width: 700px;
  }
}

.availableWords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 4px;
  width: 100%;
  min-height: 42px;
  max-height: 100%;
  background-color: var(--blue);
}

@media screen and (min-width: 390px) {
  .availableWords {
    width: 334px;
  }
}

@media screen and (min-width: 768px) {
  .availableWords {
    width: 530px;
    gap: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .availableWords {
    width: 660px;
    height: 44px;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* width: 350px; */
  gap: 20px;
}

@media screen and (min-width: 768px) {
  .container {
    flex-direction: column;
    margin: 0 auto;
    width: 350px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    /* width: 440px; */
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.wordContainerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 7px;
  height: fit-content;
  /* width: 100%; */
}

@media screen and (min-width: 768px) {
  .wordContainerWrap {
    flex-direction: row;
  }
}

/* @media screen and (min-width: 1280px) {
  .wordContainerWrap {
    height: 60px;
  }
} */

.wordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 2px 2px 2px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  height: 40px;
  min-width: 40px;
  width: max-content;
  flex-wrap: wrap;
  /* row-gap: 10px;
  column-gap: 10px; */
  background-color: white;
}

.wordContainer:hover {
  cursor: pointer;
  background-color: #e0e3e8;
}

/* @media screen and (min-width: 390px) {
  .wordContainer {
    width: 30px;
  }
}

@media screen and (min-width: 768px) {
  .wordContainer {
    width: 30px;
  }
} */

/* .wordInContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0px;
} */

.textWrap {
  display: flex;
  padding: 2px 6px 2px 6px;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  min-height: 40px;
  /* width: 50px; */
  width: max-content;
  height: fit-content;
  flex-wrap: wrap;
  /* row-gap: 10px;
  column-gap: 10px; */
  background-color: white;
  gap: 2px;
}

/* @media screen and (min-width: 390px) {
  .textWrap {
    width: 50px;
  }
}
@media screen and (min-width: 768px) {
  .textWrap {
    width: 50px;
  }
} */

.text {
  color: #364f6a;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  word-break: keep-all;

  /* width: fit-content;
  min-width: 130px; */
}

.cardWrap {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 8px;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: white;
  border: 1px solid #364f6a;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  height: 30px;
  /* width: 34px; */
  width: fit-content;

  color: #364f6a;
  font-weight: 400;
}

@media screen and (min-width: 1280px) {
  .card {
    font-weight: 500;
  }
}

.card:hover {
  background-color: #e0e3e8;
}

.cardInContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background-color: white;
  /* border: 1px solid #364f6a;
  border-radius: 4px; */
  cursor: pointer;
  user-select: none;
  height: 34px;
  color: #364f6a;
  font-weight: 400;
}

@media screen and (min-width: 1280px) {
  .cardInContainer {
    font-weight: 500;
  }
}

.cardInContainer:hover {
  cursor: pointer;
  background-color: #e0e3e8;
}

.incorrect {
  border: 1px solid red;
}

.correct {
  border: 1px solid green;
}

.selected {
  border: 1px solid #399e5a;
}
