.closeEditBtn {
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  border: none;
  opacity: 0.75;
  cursor: pointer;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);
  padding-right: 30px;
}

.closeEditBtn:hover,
.closeEditBtn:focus {
  opacity: 1;
  box-shadow: rgba(100, 100, 111, 0.2);
}
