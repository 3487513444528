.addWordsToContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  width: 288px;
  background-color: #e0e3e8;
  margin: 0 auto;
  padding: 10px;
}

@media screen and (min-width: 390px) {
  .addWordsToContainer {
    width: 358px;
  }
}

@media screen and (min-width: 768px) {
  .addWordsToContainer {
    width: 556px;
  }
}

@media screen and (min-width: 1280px) {
  .addWordsToContainer {
    /* height: 350px; */
    width: 700px;
  }
}

.availableWords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 12px;
  border-radius: 4px;
  width: 100%;
  min-height: 42px;
  height: 100%;
  background-color: white;
}

@media screen and (min-width: 390px) {
  .availableWords {
    width: 320px;
  }
}

@media screen and (min-width: 768px) {
  .availableWords {
    width: 530px;
  }
}

@media screen and (min-width: 1280px) {
  .availableWords {
    width: 660px;
    /* height: 44px; */
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 320px;
  gap: 10px;
}

@media screen and (min-width: 768px) {
  .container {
    flex-direction: row;
    gap: 10px;
    margin: 0 auto;
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    flex-direction: row;
    width: 440px;
    gap: 20px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.leftContainerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 120px;
  height: 100%;
  /* gap: 10px; */
  width: 258px;
  row-gap: 10px;
  column-gap: 10px;
}

@media screen and (min-width: 768px) {
  .leftContainerWrap {
    width: 100%;
    /* height: 200px; */
  }
}

/* @media screen and (min-width: 1280px) {
  .leftContainerWrap {
    height: 200px;
  }
} */

.text {
  color: #364f6a;
  justify-content: center;
  font-weight: 400;
}

.cardWrap {
  display: flex;
  /* gap: 8px; */
  row-gap: 10px;
  column-gap: 10px;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  background-color: white;
  border: 1px solid #364f6a;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  height: 30px;
  color: #364f6a;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .card {
    height: 34px;
    padding: 2px 8px;
    font-weight: 500;
  }
}
@media screen and (min-width: 1280px) {
  .card {
    height: 34px;
    padding: 2px 8px;
    font-weight: 500;
  }
}

.card:hover {
  background-color: #e0e3e8;
}

.leftContainer {
  display: flex;
  padding: 8px 12px;
  /* border: 1px solid rgba(0, 0, 0, 0.23); */
  border: 1px solid #e0e3e8;

  border-radius: 4px;
  min-height: 110px;

  width: 266px;
  flex-wrap: wrap;
  /* justify-content: center;
  align-items: center; */

  background-color: white;
}

@media screen and (min-width: 390px) {
  .leftContainer {
    width: 320px;
  }
}

@media screen and (min-width: 768px) {
  .leftContainer {
    width: 260px;
  }
}

@media screen and (min-width: 1280px) {
  .leftContainer {
    width: 320px;
  }
}

.rightContainer {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #e0e3e8;
  border-radius: 4px;
  min-height: 110px;
  width: 266px;
  flex-wrap: wrap;
  background-color: white;
}

@media screen and (min-width: 390px) {
  .rightContainer {
    width: 320px;
  }
}

@media screen and (min-width: 768px) {
  .rightContainer {
    width: 260px;
  }
}

@media screen and (min-width: 1280px) {
  .rightContainer {
    /* height: 200px; */
    width: 320px;
  }
}

.incorrect {
  border: 1px solid red;
}

.correct {
  border: 1px solid green;
}
