.textWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;

  border: 1px solid #e0e3e8;
  border-radius: 5px;
  width: 288px;
  margin: 0 auto;
  padding: 14px;
}

@media screen and (min-width: 390px) {
  .textWrap {
    width: 358px;
  }
}

@media screen and (min-width: 768px) {
  .textWrap {
    width: 736px;
  }
}

@media screen and (min-width: 1280px) {
  .textWrap {
    width: 900px;
    gap: 10px;
    padding: 20px;
    margin: 0;
  }
}

.text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  color: #364f6a;
}

.text p {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: normal;
}

.wordWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  color: #364f6a;
}

/* .words {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: normal;
} */

.correctAnswer {
  border: 2px solid green;
}

.incorrectAnswer {
  border: 2px solid red;
}

.input {
  width: 110px;
  height: 30px;

  padding: 3px 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

@media screen and (min-width: 1280px) {
  .input {
    padding: 8px 12px;
    /* margin-bottom: 10px; */
  }
}

.input:focus {
  outline: none;
  border-color: #364f6a;
}
