.quiz {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.options {
  width: 268px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 10px;
  gap: 4px;
  border-radius: 5px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  background-color: white;
}

@media screen and (min-width: 390px) {
  .options {
    width: 338px;
  }
}

@media screen and (min-width: 768px) {
  .options {
    width: 536px;
  }
}

@media screen and (min-width: 1280px) {
  .options {
    width: 792px;
    padding: 10px 26px;
  }
}

/* @media screen and (min-width: 768px) {
  .options {
    height: 136px;
  }
} */

.optionWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.question {
  color: var(--main-color);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 6px;
  /* margin-left: 26px; */
}

@media screen and (min-width: 768px) {
  .question {
    font-size: 17px;
    /* font-weight: 400; */
  }
}

.option {
  width: 3px;
  height: 3px;

  padding: 6px;
  border: 1px solid #94a4b2;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

@media screen and (min-width: 768px) {
  .option {
    width: 4px;
    height: 4px;
    padding: 7px;
  }
}

.option:hover {
  background-color: #94a4b2;
}

.option.default {
  background-color: #ffffff;
}

.option.green {
  background-color: #00ff00;
}

.option.red {
  background-color: #ff0000;
}

.text {
  font-weight: 400;
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  .text {
    font-weight: 400;
    font-size: 17px;
  }
}
