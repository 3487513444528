.exerciseWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* margin-bottom: 14px; */
  padding: 10px 30px;
  border: 1px solid var(--grey);
  border-radius: 4px;
}

@media screen and (min-width: 390px) {
  .exerciseWrap {
    width: 358px;
  }
}

@media screen and (min-width: 768px) {
  .exerciseWrap {
    width: 556px;
    flex-direction: row;
    /* justify-content: flex-start;
    align-items: center; */
    padding: 0px;
    border: none;
    padding: 10px 10px;
    gap: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .exerciseWrap {
    width: 556px;
    flex-direction: row;
    /* justify-content: flex-start;
    align-items: center; */
    padding: 0px;
    border: none;
    padding: 4px 10px 4px 12px;
  }
}

.wordWrap {
  align-items: center;
}

@media screen and (min-width: 768px) {
  .wordWrap {
    width: 84px;
    /* width: fit-content; */
  }
}

.correctAnswer {
  border: 2px solid green;
}

.incorrectAnswer {
  border: 2px solid red;
}

.input {
  width: 224px;
  height: 30px;
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  background-color: #fff;
  transition: border-color 0.3s ease;
  margin-bottom: 0px;
}

@media screen and (min-width: 768px) {
  .input {
    width: 200px;
    margin-right: 15px;
  }
}

@media screen and (min-width: 1280px) {
  .input {
    width: 220px;
    margin-right: 15px;
  }
}

.input:focus {
  outline: none;
  border-color: #364f6a;
}
