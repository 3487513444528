.testContainer {
  background-color: var(--blue);
  padding: 10px 10px 20px 10px;
  width: 288px;
  border-radius: 5px;
  margin-bottom: 70px;
}

@media screen and (min-width: 390px) {
  .testContainer {
    width: 358px;
  }
}

@media screen and (min-width: 768px) {
  .testContainer {
    width: 556px;
  }
}

@media screen and (min-width: 1280px) {
  .testContainer {
    width: 820px;
    padding: 14px 14px 20px 14px;
  }
}

.testHeaderWrap {
  display: flex;
  flex-direction: column;
  width: 268px;
  height: 100%;
  background-color: var(--blue);
  color: white;
  font-size: 17px;
  padding: 0px 0px 20px 0px;
  border-radius: 5px;
}

@media screen and (min-width: 390px) {
  .testHeaderWrap {
    width: 338px;
  }
}

@media screen and (min-width: 768px) {
  .testHeaderWrap {
    width: 536px;
  }
}

@media screen and (min-width: 1280px) {
  .testHeaderWrap {
    width: 792px;
    gap: 30px;
  }
}

/* .testHeaderTitleWrap {
  display: flex;
  justify-content: space-between;
} */

.testHeaderTimeWrap {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}

/* .timeText {
  font-weight: 500;
} */

.testHeaderListWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1280px) {
  .testHeaderListWrap {
    gap: 20px;
  }
}

.testHeaderList {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

@media screen and (min-width: 1280px) {
  .testHeaderList {
    flex-direction: row;

    gap: 20px;
  }
}

.testHeaderItem {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

@media screen and (min-width: 1280px) {
  .testHeaderItem {
    gap: 6px;
  }
}

.testButtonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  padding: 6px 16px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #364f6a;
  border-radius: 5px;
  color: #90adc6;
  background-color: #364f6a;
}

.testButton:hover,
.testButton:focus {
  color: var(--yellow);
}

.testButtonWrapEnd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
