.box {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 30px;
  /* border: 1px solid #364f6a; */
  padding: 6px 10px;
  font-size: 17px;
  cursor: pointer;
  background-color: white;

  border-radius: 5px;
  color: #364f6a;
}

@media screen and (min-width: 390px) {
  .box {
    width: 200px;
    height: 34px;
    /* font-size: 18px; */
  }
}

@media screen and (min-width: 768px) {
  .box {
    width: 100%;
    height: 32px;
    /* font-size: 18px; */
  }
}

@media screen and (min-width: 1280px) {
  .box {
    height: 32px;
  }
}

.box:hover {
  background-color: #e0e3e8;
}

.disabledBox {
  display: none;
}

.selected {
  border: 2px solid #f8bc02;
}
