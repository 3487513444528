@import-normalize; /* bring in normalize.css styles */

:root {
  --grey: #e0e3e8;
  --yellow: #f8bc02;
  --blue: #90adc6;
  --dark-blue: #94a4b2;
  --main-color: #364f6a;
  --red: #a53f2b;
  --green: #399e5a;
  --orange: #ff8811;
  --beidge: #d6cbc1;
  --timberwolf: #cdd6d0;

  --gradient-blue: linear-gradient(315deg, #419ef1 0%, #9bd0ff 100%);
  --box-title-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --box-shadow: 3px 8px 14px 0px rgba(136, 198, 253, 0.19);
  --filter-drop-shadow: drop-shadow(7px 13px 14px rgba(116, 177, 232, 0.24));
}

body {
  margin: 0;
  padding: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}
