.scroll {
  max-height: 300px;
  overflow-y: auto;
}

.btnWrap {
  margin-left: 0px;
}

@media screen and (min-width: 768px) {
  .btnWrap {
    margin-left: 80px;
  }
}

@media screen and (min-width: 1280px) {
  .btnWrap {
    margin-left: 145px;
  }
}

.title {
  margin-top: 10px;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .title {
    margin-left: 80px;
    margin-bottom: 20px;
    font-size: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .title {
    margin-left: 145px;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 24px;
  }
}

.formWrap {
  display: flex;
  flex-direction: column;
}
.form {
  margin-bottom: 45px;

  height: 100%;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e0e3e8;
  gap: 14px;
  padding: 14px;
}

@media screen and (min-width: 768px) {
  .form {
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 45px;
  }
}

@media screen and (min-width: 1280px) {
  .form {
    margin-left: 145px;
    margin-right: 145px;
    margin-bottom: 45px;
  }
}

.textLabel {
  font-size: 16px;
  font-weight: 400;
  color: #364f6a;
}

@media screen and (min-width: 768px) {
  .textLabel {
    font-size: 18px;
    font-weight: 500;
  }
}

.textFieldWrap {
  display: flex;
  flex-direction: column;
}

.text {
  background-color: white;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  width: 100%;
  height: fit-content;
  font-size: 18px;
  padding: 6px;
}

.image {
  width: 100%;
}

@media screen and (min-width: 1280px) {
  .image {
    width: 30%;
  }
}

.select {
  background-color: white;
}

.buttonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 240px;
  height: 44px;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 30px;

  border: 1px solid #364f6a;
  cursor: pointer;
  color: #90adc6;
  background-color: #364f6a;
}

.button:hover {
  background-color: #f8bc02;
  color: #364f6a;
}

.imgWrap {
  width: 240px;
  height: 194px;
}

.imgBtnWrap {
  width: 240px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.fileInput {
  display: none;
}

.customFileInput {
  width: fit-content;

  background-color: #90adc6;
  color: #fff;
  padding: 6px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.customFileInput:hover {
  background-color: #f8bc02;
}

.cancelBtn {
  width: fit-content;
  background-color: #90adc6;
  color: #fff;
  padding: 6px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.cancelBtn:hover {
  background-color: #f8bc02;
  color: #fff;
}
