.box {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #364f6a;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  color: #364f6a;
}

@media screen and (min-width: 768px) {
  .box {
    width: 28px;
    height: 28px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1280px) {
  .box {
    width: 32px;
    height: 32px;
    font-size: 20px;
    margin-bottom: 8px;
  }
}
