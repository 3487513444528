.goBackBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 5px 0px;
  color: #364f6a;
  cursor: pointer;
  font-size: 16px;
}

.goBackBtn:hover {
  color: #399e5a;
}

.goBackBtnText {
  margin-left: 5px;
  font-size: 16px;
}
