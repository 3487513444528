.editBtn {
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  border: none;
  padding: 0px;
  /* opacity: 0.75; */
  cursor: pointer;
  /* transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1); */

  /* position: absolute; */
  /* top: -25px;
  right: 14px;
  transform: translate(0, -100%); */
}
.editBtn:hover,
.editBtn:focus {
  opacity: 1;
  box-shadow: rgba(100, 100, 111, 0.2);
}

@media screen and (min-width: 768px) {
  .editBtn {
    top: 18px;
    right: 18px;
    transform: translate(0, 0);
  }
}
