rules {
  width: 700px;
  height: 300px;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  /* display: flex;
  flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #e0e3e8;
}
