.loginHeader {
  background-color: var(--main-color);
  padding: 6px 12px;
}

.forgetPsw {
  /* padding: 0px 60px; */
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  text-decoration: none;
  color: var(--blue);
}

.forgetPsw:hover,
.forgetPsw:focus {
  color: var(--yellow);
}

.restorePswReturn {
  display: flex;
  padding: 0px 0px 20px 0px;
  align-items: center;
  gap: 4px;
  /* margin-top: 5px; */
  text-decoration: none;
  color: var(--blue);
}

.restorePswReturn:hover,
.restorePswReturn:focus {
  color: var(--yellow);
}

.messageEmailSent {
  display: flex;
  justify-content: center;
  padding: 20px 10px;

  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.messageEmailSentLink {
  text-decoration: none;
  color: var(--blue);
  /* display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px; */
}

.messageEmailSentLink:hover,
.messageEmailSentLink:focus {
  color: var(--yellow);
}
