.container {
  /* padding: 0px 10px 0px 10px; */
  width: 100%;
  margin: 0 auto;
  /* background-color: #364f6a; */

  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 0px 32px 0 32px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    padding: 0px 16px 0px 16px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    padding: 0px 28px 0px 36px;
  }
}
