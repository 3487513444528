.bgImage {
  background-image: url(../../../src/assets/images/bg-profile.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  width: 100%;
  height: 100%;
  background-color: #364f6a;
}

.sectionAvatar {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
}
