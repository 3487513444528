.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(97, 97, 97, 0.6);
  backdrop-filter: blur(4px);

  z-index: 9999;
}

@media screen and (min-width: 768px) {
  .modalOverlay {
    background: rgba(43, 43, 43, 0.6);
    backdrop-filter: none;
  }
}

.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 278px;
  padding: 12px 12px 60px 12px;

  border-radius: 20px;
  background: white;
}

@media screen and (min-width: 390px) {
  .modalContainer {
    padding: 20px 20px 64px 20px;
    width: 320px;

    border-radius: 10px;
    box-shadow: 3px 8px 14px 0px rgba(136, 198, 253, 0.19);
  }
}

@media screen and (min-width: 768px) {
  .modalContainer {
    padding: 24px 24px 64px 24px;
    width: 650px;

    border-radius: 10px;
    box-shadow: 3px 8px 14px 0px rgba(136, 198, 253, 0.19);
  }
}

.modalCrossBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;
  height: 24px;
  width: 24px;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.modalText {
  padding: 41px 0px 44px 0px;
  color: #364f6a;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.96px;
}

@media screen and (min-width: 768px) {
  .modalText {
    text-align: center;
    font-size: 30px;
    /* letter-spacing: 1.44px; */
    padding: 50px 150px 50px 150px;
  }
}

.modalCancelBtn {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  /* border: 2px solid #f8bc02; */
  background: #364f6a;
  cursor: pointer;

  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.64px;
}

.modalCancelBtn:hover,
.modalCancelBtn:focus {
  background: #90adc6;
  color: white;
  border: none;
}

@media screen and (min-width: 390px) {
  .modalCancelBtn {
    height: 50px;
    font-size: 20px;
  }
}

.modalYesBtn {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 5px;
  /* border: 2px solid #f8bc02; */
  background: #364f6a;
  cursor: pointer;

  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.64px;
}

.modalYesBtn:hover,
.modalYesBtn:focus {
  background: #90adc6;
  color: white;
  border: none;
}

@media screen and (min-width: 390px) {
  .modalYesBtn {
    height: 50px;
    font-size: 20px;
  }
}

.modalActions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 0px;
  width: 100%;
  align-items: center;
}

@media screen and (min-width: 390px) {
  .modalActions {
    gap: 14px;
  }
}

@media screen and (min-width: 768px) {
  .modalActions {
    display: flex;
    flex-direction: row;
    gap: 17px;
    padding: 0 142px;
    align-items: center;
  }
}
