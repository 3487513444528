.checkBoxContainer {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  gap: 5px;
  /* margin-top: 10px; */
}

/* @media screen and (min-width: 768px) {
  .checkBoxContainer {
    margin-top: 0px;
  }
} */

.circleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.circle {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #7e7e7e;
}

.correctly {
  background-color: green;
}

.incorrectly {
  background-color: red;
}
