.img {
  width: 90px;
}

@media screen and (min-width: 1280px) {
  .img {
    width: 150px;
  }
}

.correctAnswer {
  border: 2px solid green;
}

.incorrectAnswer {
  border: 2px solid red;
}

.input {
  width: 100%;
  padding: 3px 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

@media screen and (min-width: 1280px) {
  .input {
    /* width: 50%; */

    padding: 8px 12px;
    margin-bottom: 10px;
  }
}

.input:focus {
  outline: none;
  border-color: #364f6a;
}
