.title {
  padding-left: 14px;
  padding-right: 14px;

  color: #364f6a;
}

@media screen and (min-width: 768px) {
  .title {
    padding-left: 45px;
    padding-right: 45px;
    font-size: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .title {
    padding-left: 145px;
    font-size: 22px;
  }
}

.form {
  margin-left: 14px;
  margin-right: 14px;
  height: 100%;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0e3e8;
  gap: 18px;
  padding: 18px 10px;
}

@media screen and (min-width: 768px) {
  .form {
    margin-left: 45px;
    margin-right: 45px;
    flex-direction: row;
  }
}

@media screen and (min-width: 1280px) {
  .form {
    margin: 0px 145px 40px 145px;
    height: 300px;
  }
}

.textField {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

@media screen and (min-width: 1280px) {
  .textField {
    flex-direction: row;
    gap: 15px;
  }
}

.textFieldWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.text {
  background-color: white;
  border: 1px solid #e0e3e8;
  border-radius: 5px;
  width: 260px;
}

@media screen and (min-width: 390px) {
  .text {
    width: 300px;
  }
}

@media screen and (min-width: 1280px) {
  .text {
    width: 240px;
  }
}

.select {
  background-color: white;
  width: 260px;
}

@media screen and (min-width: 390px) {
  .select {
    width: 300px;
  }
}

@media screen and (min-width: 1280px) {
  .select {
    width: 190px;
  }
}

.button {
  width: 140px;
  height: 40px;
  border: 1px solid #e0e3e8;
  border-radius: 4px;
  font-size: 16px;
  margin-left: 10px;
  border: 1px solid #364f6a;
  cursor: pointer;
  color: #f8bc02;
  background-color: #364f6a;
}

.button:hover {
  color: var(--blue);
}

.button:focus {
  color: var(--blue);
}
