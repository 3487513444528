.box {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 2px;
  font-size: 17px;
  cursor: pointer;
  background-color: white;

  border-radius: 5px;
  color: #364f6a;
}

@media screen and (min-width: 390px) {
  .box {
    width: 28px;
    height: 28px;
  }
}

@media screen and (min-width: 768px) {
  .box {
    width: 28px;
    height: 28px;
  }
}

/* @media screen and (min-width: 1280px) {
  .box {
    width: 32px;
    height: 32px;
  }
} */

.box:hover {
  background-color: #e0e3e8;
}

.disabledBox {
  display: none;
}

.selected {
  border: 2px solid #f8bc02;
}
