.footer {
  display: flex;
  color: white;
  background-color: var(--grey);
  justify-content: space-between;
  align-items: center;
  padding: 9px 8px 9px 12px;
  z-index: 9999;
}

@media screen and (min-width: 768px) {
  .footer {
    gap: 22px;
    padding: 0px 10px;
  }
}

@media screen and (min-width: 1280px) {
  .footer {
    gap: 0;
    justify-content: space-between;
  }
}
